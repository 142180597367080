import * as React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'

import Seo from '~components/shared/seo'
import Heading from '~components/shared/heading'
import ContentBox from '~components/shared/content-box'
import ContentBoxTitle from '~components/shared/content-box-title'
import ProfileSection from '~components/pages/account/profile-section'
import DetailsSection from '~components/pages/account/details-section'
import SettingsSection from '~components/pages/account/settings-section'
import ThemeSelection from '~components/pages/account/theme-selection'

const AccountPage = () => {
  return (
    <>
      <Seo title="Account" />
      <Heading>Account</Heading>
      <Grid gap={[4, 6]}>
        <GridItem>
          <ProfileSection />
        </GridItem>
        <GridItem>
          <DetailsSection />
        </GridItem>
        <GridItem>
          <ContentBox>
            <ContentBoxTitle>Settings</ContentBoxTitle>
            <SettingsSection />
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox>
            <ContentBoxTitle>Theme</ContentBoxTitle>
            <ThemeSelection />
          </ContentBox>
        </GridItem>
      </Grid>
    </>
  )
}

export default AccountPage
