import React from 'react'
import { Box, BoxProps, Skeleton, useColorModeValue } from '@chakra-ui/react'

interface Props extends BoxProps {
  hasMargin?: boolean
  isLoading?: boolean
}

const ContentBox: React.FC<Props> = ({
  hasMargin,
  isLoading,
  children,
  ...props
}) => {
  const bgColor = useColorModeValue('gray.50', 'gray.800')

  return (
    <Box
      h="100%"
      p={[4, 6]}
      rounded="2xl"
      overflow="hidden"
      transition="all .2s"
      bgColor={bgColor}
      my={hasMargin ? [4, 6] : undefined}
      {...props}
    >
      {isLoading ? (
        <>
          <Skeleton h="24px" w="50%" />
          <Skeleton h="24px" w="100%" mt={2} />
        </>
      ) : (
        children
      )}
    </Box>
  )
}

export default ContentBox
