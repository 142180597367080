import React from 'react'
import {
  Text,
  Image,
  HStack,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  AspectRatio,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'

import LightPreview from '~images/theme/light-preview.svg'
import DarkPreview from '~images/theme/dark-preview.svg'

const ThemeSelection: React.FC = () => {
  const { colorMode, setColorMode } = useColorMode()

  return (
    <RadioGroup
      size="lg"
      colorScheme="brand"
      value={colorMode}
      onChange={setColorMode}
      name="theme-selection"
    >
      <Grid
        gap={[4, 6]}
        maxW="500px"
        templateColumns={['repeat(2,1fr)', 'repeat(2,1fr)']}
      >
        <SelectionItem label="Light" value="light" image={LightPreview} />
        <SelectionItem label="Dark" value="dark" image={DarkPreview} />
      </Grid>
    </RadioGroup>
  )
}

interface Props {
  label: string
  value: string
  image: any
}

const SelectionItem: React.FC<Props> = ({ label, value, image }) => {
  const { colorMode, setColorMode } = useColorMode()
  const isActive = colorMode === value
  const borderColor = useColorModeValue('gray.200', 'gray.600')

  return (
    <GridItem
      rounded="xl"
      cursor="pointer"
      overflow="hidden"
      border="2px solid"
      transform="translateZ(0)"
      borderColor={isActive ? 'brand.300' : borderColor}
      onClick={() => setColorMode(value)}
    >
      <AspectRatio ratio={16 / 8.4} borderBottomWidth="1px">
        <Image src={image} objectFit="cover" w="100%" />
      </AspectRatio>
      <HStack px={[3, 4]} py={2}>
        <Radio value={value} />
        <Text fontSize={['sm', 'md']} fontWeight="semibold">
          {label}
        </Text>
      </HStack>
    </GridItem>
  )
}

export default ThemeSelection
