import React from 'react'
import { FormControl, FormLabel, Switch } from '@chakra-ui/react'

import { useUser } from '~context/user'

const SettingsSection: React.FC = () => {
  const { user } = useUser()
  const { personalized_newsletter_optIn } = user

  return (
    <>
      <FormControl display="flex" alignItems="center">
        <Switch colorScheme="brand" id="email-alerts" isChecked={false} />
        <FormLabel htmlFor="email-alerts" mb={0} ml={4}>
          Email when an order has been received
        </FormLabel>
      </FormControl>
      <FormControl display="flex" alignItems="center" my={2}>
        <Switch colorScheme="brand" id="settings-2" isChecked={false} />
        <FormLabel htmlFor="settings-2" mb={0} ml={4}>
          Email when an order has been fulfilled
        </FormLabel>
      </FormControl>
      <FormControl display="flex" alignItems="center">
        <Switch
          id="settings-3"
          colorScheme="brand"
          isChecked={personalized_newsletter_optIn}
        />
        <FormLabel htmlFor="settings-3" mb={0} ml={4}>
          Subscribe to newsletter
        </FormLabel>
      </FormControl>
    </>
  )
}

export default SettingsSection
