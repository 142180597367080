import React from 'react'
import {
  Box,
  Skeleton,
  Heading as ChakraHeading,
  HeadingProps,
} from '@chakra-ui/react'

interface Props extends HeadingProps {
  isLoading?: boolean
}

const Heading: React.FC<Props> = ({ isLoading, ...props }) => {
  return (
    <Box mb={isLoading ? [4, 4, 6, 8] : 0}>
      <Skeleton w={isLoading ? '300px' : 'auto'} isLoaded={!isLoading}>
        <ChakraHeading
          mb={isLoading ? 0 : [4, 4, 6]}
          size="lg"
          display="inline-block"
          textDecor="underline"
          textUnderlineOffset="2px"
          textDecorationThickness="3px"
          textDecorationColor="brand.300"
          {...props}
        />
      </Skeleton>
    </Box>
  )
}

export default Heading
