import React from 'react'
import { Text, TextProps, useColorModeValue } from '@chakra-ui/react'

const ContentBoxTitle: React.FC<TextProps> = props => {
  const color = useColorModeValue('gray.500', 'gray.400')
  return (
    <Text
      mb={2}
      noOfLines={1}
      fontWeight="semibold"
      textTransform="capitalize"
      color={color}
      {...props}
    />
  )
}

export default ContentBoxTitle
