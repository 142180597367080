import React from 'react'
import { Text, Grid, GridItem } from '@chakra-ui/react'

import ContentBox from '~components/shared/content-box'
import ContentBoxTitle from '~components/shared/content-box-title'
import { useUser } from '~context/user'

const AccountDetailsSection: React.FC = () => {
  const { user } = useUser()

  return (
    <Grid
      gap={[4, 6]}
      templateColumns={[
        'repeat(2, 1fr)',
        'repeat(2, 1fr)',
        'repeat(4, 1fr)',
        'repeat(2, 1fr)',
        'repeat(4, 1fr)',
      ]}
    >
      <GridItem>
        <ContentBox>
          <ContentBoxTitle>Partner</ContentBoxTitle>
          <Text>{user.partner?.name}</Text>
        </ContentBox>
      </GridItem>
      <GridItem>
        <ContentBox>
          <ContentBoxTitle>Primary Badge</ContentBoxTitle>
          <Text>{user.primary_badge?.name || 'N/A'}</Text>
        </ContentBox>
      </GridItem>
      <GridItem>
        <ContentBox>
          <ContentBoxTitle>Membership</ContentBoxTitle>
          <Text>{user.membership}</Text>
        </ContentBox>
      </GridItem>
      <GridItem>
        <ContentBox>
          <ContentBoxTitle>Balance</ContentBoxTitle>
          <Text>{user.credit_balance.toLocaleString()} credits</Text>
        </ContentBox>
      </GridItem>
    </Grid>
  )
}

export default AccountDetailsSection
