import React from 'react'
import {
  Box,
  Text,
  Avatar,
  HStack,
  Skeleton,
  AspectRatio,
  useColorModeValue,
} from '@chakra-ui/react'

import ContentBox from '~components/shared/content-box'
import { useUser } from '~context/user'

interface Props {
  loading?: boolean
  image?: string
  email?: string
  phone?: string
  firstName?: string
  lastName?: string
}

const ProfileSection: React.FC<Props> = ({
  loading,
  image,
  email,
  phone,
  firstName,
  lastName,
}) => {
  const { user } = useUser()
  const borderColor = useColorModeValue('gray.100', 'gray.700')

  const name = `${firstName || user.first_name} ${lastName || user.last_name}`

  return (
    <ContentBox
      border="3px solid"
      borderColor={borderColor}
      boxShadow="rgb(149 157 165 / 15%) 0px 8px 32px"
    >
      <HStack spacing={4}>
        <AspectRatio
          w="80px"
          shadow="xl"
          rounded="2xl"
          overflow="hidden"
          ratio={1 / 1}
        >
          <Skeleton isLoaded={!loading}>
            <Avatar
              w="100%"
              h="100%"
              borderRadius="none"
              name={name}
              src={email ? image : user.profile_img?.url}
            />
          </Skeleton>
        </AspectRatio>
        <Box>
          <Skeleton isLoaded={!loading}>
            <Text fontSize="lg" fontWeight="bold">
              {name}
            </Text>
          </Skeleton>
          {!loading && <Text>{email || user.email}</Text>}
          {!loading && <Text>{phone || user.phone}</Text>}
        </Box>
      </HStack>
    </ContentBox>
  )
}

export default ProfileSection
